// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$xengine-web-theme: mat.define-theme(
    (
        color: (
            theme-type: light,
            primary: mat.$azure-palette,
            tertiary: mat.$blue-palette,
        ),
        density: (
            scale: 0,
        ),
        typography: (
            plain-family: 'Inter, system-ui, sans-serif',
            brand-family: sans-serif,
            bold-weight: 900,
            medium-weight: 500,
            regular-weight: 300,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
    @include mat.all-component-themes($xengine-web-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($xengine-web-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($xengine-web-theme);
/* You can add global styles to this file, and also import other style files */

// Main style
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Demos */
@import "./metronic/css/demos/demo1.css";

// Keenicons
@import "./metronic/vendors/keenicons/duotone/style.css";
@import "./metronic/vendors/keenicons/filled/style.css";
@import "./metronic/vendors/keenicons/outline/style.css";
@import "./metronic/vendors/keenicons/solid/style.css";

// html,
// body {
//     height: 100%;
// }
// body {
//     margin: 0;
//     font-family: Roboto, "Helvetica Neue", sans-serif;
// }

/* -------------------------------------------------------------------------- */
/*  @ Table
/* -------------------------------------------------------------------------- */
.mat-mdc-table {
    font-family: Inter, system-ui, sans-serif;
    border: var(--tw-table-border);
    .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
        background: none !important;
    }

    .mat-mdc-header-row {
        background: var(--tw-table-head-background-color);
        height: 42px;
    }

    .mat-mdc-row{
        height: 42px;
    }
}

:root {
    --mat-table-background-color: var(--tw-card-background-color);
    --mat-cell-font-size: 0.875rem;
    --mat-column-border-color: var(--tw-border-color);
    --mat-paginator-background-color: var(--tw-card-background-color);
    --mat-paginator-color: var(--tw-gray-800);
    --mat-paginator-container-size: 42px;
    --mat-paginator-form-field-container-height: 32px;
    --mat-paginator-form-field-container-vertical-padding: 8px;
    --mat-sort-arrow-color: var(--tw-gray-600);

    --mat-option-selected-state-layer-color: var(--tw-card-background-color);
}

.mat-mdc-form-field-infix {
    .mat-mdc-select {
        // background: var(--tw-dropdown-background-color);
        color: var(--tw-gray-800);
    }
}

.cdk-overlay-pane {
    .mat-mdc-select-panel {
        background: var(--tw-table-head-background-color);
        .mat-mdc-option {
            color: var(--tw-gray-800);
        }
    }
}

.mat-mdc-select {
    display: flex;
    align-items: center;

    &:focus {
        .mat-mdc-select-trigger {
            .mat-mdc-select-value {
                @apply text-primary #{'!important'};
            }

            // .mat-mdc-select-arrow-wrapper {
            //     .mat-mdc-select-arrow {
            //         border-top-color: var(
            //             --fuse-primary
            //         ) !important;
            //     }
            // }
        }
    }

    .mat-mdc-select-trigger {
        display: flex;
        align-items: center;

        .mat-mdc-select-value {
            display: flex;
            max-width: none;

            mat-mdc-select-trigger {
                .mat-icon {
                    margin: 0 !important;
                }
            }
        }

        .mat-mdc-select-arrow-wrapper {
            display: flex;
            align-items: center;
            transform: none;
            margin-left: 4px;

            .mat-mdc-select-arrow {
                min-height: 0;
                @apply text-gray-500 dark:text-gray-400 #{'!important'};
            }
        }
    }
}
